import { useEffect, useState } from 'react'
import { useApolloClient, useMutation } from '@apollo/client'
import { differenceInDays } from 'date-fns'
import { toast } from 'react-toastify'
import { CLAIM_ST, GET_UPGRADES } from '../../../../apis/graphql/requests'
import { useAppConfig } from '../../../../contexts/AppConfigProvider'
import ProgressBar from '@ramonak/react-progress-bar'
import { w3 } from '../../../../apis/contract'
import { useLocalizedText } from '../../../../hooks/useLocalizedText'

const claimUnits = [
    [1, 500, 10],
    [51, 100, 4.5],
    [101, 150, 4],
    [151, 200, 3.5],
    [201, 250, 3],
    [251, 300, 2.5],
    [301, 350, 2],
    [351, 400, 1.5],
    [401, 450, 1],
    [451, Infinity, 0.5],
]

export const ClaimList = () => {
    const { userData, updateLoadingState } = useAppConfig()
    const client = useApolloClient()
    const { translate } = useLocalizedText()

    const [claimST] = useMutation(CLAIM_ST)

    const [histories, setHistories] = useState<
        Array<{
            id: string
            upgradedLevel: number
            orderNo: number
            createdAt: string
            referralParentAddress: string
            levelParentAddress: string
            claimedAmount: number
        }>
    >()
    const [currentDate, setCurrentDate] = useState<string>('0')

    const loadUpgradeList = async () => {
        updateLoadingState(true)

        await client
            .query({
                query: GET_UPGRADES,
                fetchPolicy: 'network-only',
                variables: {
                    address: userData.addr,
                },
            })
            .then((response) => {
                const data = response.data.getUpgradeHistories
                if (data) {
                    setHistories(data.histories)
                    setCurrentDate(data.currentDate)
                }
            })
            .catch((e) => {
                toast.warn(e.message)
            })
            .finally(() => {
                updateLoadingState(false)
            })
    }

    useEffect(() => {
        loadUpgradeList()
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [])

    const handleClaimST = async (id: string) => {
        try {
            updateLoadingState(true)

            const signature = await w3.eth.personal.sign(
                id,
                userData.addr ?? '',
                ''
            )
            await claimST({
                variables: {
                    claimSt: {
                        signature,
                        upgradeId: id,
                        walletAddress: userData.addr,
                    },
                },
            })
            updateLoadingState(false)
            toast.success(translate('successfullyClaimed'))
            // eslint-disable-next-line @typescript-eslint/no-explicit-any
        } catch (e: any) {
            toast.warning(e.message)
            updateLoadingState(false)
        }
    }

    return (
        <div className="w-ful my-5 flex h-full flex-col items-center justify-start gap-3 px-3 sm:gap-5 sm:px-5">
            {histories?.map((history, index) => {
                const difDays = differenceInDays(
                    new Date(parseInt(currentDate)),
                    new Date(parseInt(history.createdAt))
                )

                let receivedAmount = 0

                let claimUnit = 0
                for (const [min, max, unit] of claimUnits) {
                    if (history.orderNo >= min && history.orderNo <= max) {
                        claimUnit = unit
                        break
                    }
                }

                if (difDays > 100) receivedAmount = claimUnit * 100
                else receivedAmount = difDays * claimUnit

                let percent = !receivedAmount
                    ? 100
                    : ((receivedAmount - history.claimedAmount) * 100) /
                      receivedAmount

                percent = parseFloat(percent.toFixed(2))

                return (
                    <div
                        key={history.upgradedLevel}
                        className="flex w-full flex-col rounded-3xl bg-[#262626] p-3 sm:p-5">
                        <div className="font-bold text-yellow-600">
                            V{history.upgradedLevel}
                        </div>
                        <div className="mb-5 flex w-full flex-row items-center justify-between text-sm">
                            <div>
                                {claimUnit} MTX X 100{translate('days')}
                            </div>
                            <div>{claimUnit * 100} MTX</div>
                        </div>
                        <div className="flex w-full flex-row items-center justify-between text-sm">
                            <div>{translate('releasedDays')}</div>
                            <div>{`${difDays > 100 ? 100 : difDays} ${translate(
                                'days'
                            )}`}</div>
                        </div>
                        <div className="flex w-full flex-row items-center justify-between text-sm">
                            <div>{translate('totalReceivedAmount')} </div>
                            <div>{`${receivedAmount} MTX`}</div>
                        </div>
                        <div className="flex w-full flex-row items-center justify-between text-sm">
                            <div>{translate('totalClaimedAmount')}</div>
                            <div>{`${history.claimedAmount} MTX`}</div>
                        </div>
                        <ProgressBar completed={percent} className="my-2" />
                        <div className="my-2 flex w-full flex-col items-center justify-center rounded-md border-2 border-blue-500 p-1">
                            <div>{translate('claimMTXAmount')}</div>
                            <div>{receivedAmount - history.claimedAmount}</div>
                        </div>
                        <div
                            onClick={
                                percent === 0
                                    ? () => {
                                          toast.warning(
                                              translate('noClaimableAmount'),
                                              { toastId: 'no claim' }
                                          )
                                      }
                                    : index === 0
                                    ? () => {
                                          toast.warn(
                                              translate(
                                                  'upgradeRankingToAchieveClaim'
                                              ),
                                              {
                                                  toastId:
                                                      'Upgrade your ranking',
                                              }
                                          )
                                      }
                                    : () => handleClaimST(history.id)
                            }
                            className={`my-2 flex w-full flex-col items-center justify-center rounded-full ${
                                index !== 0
                                    ? 'bg-[#92d050] text-black hover:bg-[#b6f176]'
                                    : 'bg-red-500 text-white hover:bg-red-400'
                            }  p-3  hover:cursor-pointer `}>
                            {index === 0
                                ? translate('upgradeRankingToAchieveClaim')
                                : translate('claim')}
                        </div>
                    </div>
                )
            })}
        </div>
    )
}
