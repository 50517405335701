import { gql } from '@apollo/client'

export const IS_REGISTERED = gql`
    query Query($address: String!) {
        isUserExist(address: $address)
    }
`
export const LOAD_INITIAL_DATA = gql`
    query LoadInitialData($address: String!) {
        loadInitialData(address: $address) {
            currentUser {
                id
                walletAddress
                parentAddress
                createdAt
                updatedAt
            }
            myDirectTeamCount
            myRank
            stxAmount
            goldBarCount
        }
    }
`

export const CREATE_USER = gql`
    mutation Mutation($user: RegisterUserInput!) {
        createUser(user: $user) {
            id
            walletAddress
            parentAddress
            createdAt
            updatedAt
        }
    }
`
export const GET_UPGRADE_DATA = gql`
    query GetUpgradData($address: String!) {
        getUpgradData(address: $address) {
            referralParent {
                id
                walletAddress
                parentAddress
                createdAt
                updatedAt
            }
            levelParent {
                id
                walletAddress
                parentAddress
                createdAt
                updatedAt
            }
            missedParents {
                id
                walletAddress
                parentAddress
                createdAt
                updatedAt
            }
            upgradeUsdtAmount
            nextLevel
            currentUser {
                id
                walletAddress
                parentAddress
                createdAt
                updatedAt
            }
        }
    }
`
export const CREATE_UPGRADE_ROW = gql`
    mutation Mutation($upgrade: UpgradeInput!) {
        upgradeLevel(upgrade: $upgrade) {
            id
        }
    }
`

export const CONFIRM_UPGRADE = gql`
    mutation PaidConfirmForUpgrade($confirm: UpgradeConfirm!) {
        paidConfirmForUpgrade(confirm: $confirm)
    }
`

export const GET_USERS = gql`
    query GetUsers {
        getUsers {
            id
            level
            addr
            parentAddr
        }
    }
`

export const LOAD_DATA_SUBSCRIPTION = gql`
    subscription Subscription($walletAddress: String!) {
        subscribeLoadData(walletAddress: $walletAddress) {
            currentUser {
                id
                walletAddress
                parentAddress
                createdAt
                updatedAt
            }
            myDirectTeamCount
            myRank
            stxAmount
            goldBarCount
        }
    }
`

export const GET_UPGRADES = gql`
    query GetUpgradeHistories($address: String!) {
        getUpgradeHistories(address: $address) {
            currentDate
            histories {
                id
                upgradedLevel
                createdAt
                referralParentAddress
                levelParentAddress
                claimedAmount
                orderNo
            }
        }
    }
`
export const CLAIM_ST = gql`
    mutation Mutation($claimSt: ClaimSTInput!) {
        claimST(claimST: $claimSt)
    }
`
export const GET_USDT_WITHDRAW_HISTORY = gql`
    query Query($address: String!) {
        getUsdtWithdrawHistory(address: $address) {
            id
            amount
            createdAt
        }
    }
`
export const GET_STX_HISTORY = gql`
    query GetStxHistory($address: String!) {
        getStxHistory(address: $address) {
            id
            amount
            createdAt
            reason
        }
    }
`

export const GET_USDT_HISTORY = gql`
    query GetUsdtHistory($address: String!, $type: String!) {
        getUsdtHistory(address: $address, type: $type) {
            amount
            level
            reason
            addr
            createdAt
            id
        }
    }
`
export const GET_ST_CLAIM_HISTORY = gql`
    query GetClaimSTHistory($address: String!) {
        getClaimSTHistory(address: $address) {
            id
            amount
            createdAt
        }
    }
`
