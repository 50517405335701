import { useTranslation } from 'react-i18next'

export const useLocalizedText = () => {
    const { t, i18n } = useTranslation()

    const translate = (key: any): string => {
        return t(key)
    }

    const handleChangeLanguage = (lng: string) => {
        i18n.changeLanguage(lng)
    }

    return { translate, handleChangeLanguage, currentLanguage: i18n.language }
}
