import { useEffect, useState } from 'react'
import CopyToClipboard from 'react-copy-to-clipboard'
import { FaRegCopy } from 'react-icons/fa'
import QRCode from 'react-qr-code'
import {
    FacebookIcon,
    FacebookShareButton,
    LineIcon,
    LineShareButton,
    TelegramIcon,
    TelegramShareButton,
    WhatsappIcon,
    WhatsappShareButton,
} from 'react-share'
import { toast } from 'react-toastify'
import { useLocalizedText } from '../../../../hooks/useLocalizedText'

const SocialShareButtons = ({ referralLink }: { referralLink: string }) => (
    <div className="mb-7 mt-5 flex flex-row justify-around px-5">
        {[
            { Component: TelegramShareButton, Icon: TelegramIcon },
            { Component: WhatsappShareButton, Icon: WhatsappIcon },
            { Component: LineShareButton, Icon: LineIcon },
            { Component: FacebookShareButton, Icon: FacebookIcon },
        ].map(({ Component, Icon }, idx) => (
            <Component key={idx} url={referralLink}>
                <div className="h-10 w-10 rounded-full bg-orange-400">
                    <Icon
                        size={40}
                        round={true}
                        iconFillColor="white"
                        bgStyle={{ display: 'none' }}
                    />
                </div>
            </Component>
        ))}
    </div>
)

const ShareOptions = ({ referralLink }: { referralLink: string }) => {
    const { translate } = useLocalizedText()

    return (
        <>
            <div className="mt-10 mb-5 flex w-full flex-row justify-around">
                <CopyToClipboard text={referralLink}>
                    <div
                        onClick={() =>
                            toast.success(translate('referralLinkCopied'), {
                                toastId: 'referral copy',
                            })
                        }
                        className="w-2/5 rounded-lg bg-yellow-600 py-2 text-center font-bold hover:cursor-pointer hover:bg-yellow-500">
                        {translate('copyLink')}
                    </div>
                </CopyToClipboard>
            </div>
            <SocialShareButtons referralLink={referralLink} />
        </>
    )
}

const InviteContent = ({ userData }: { userData: any }) => {
    const { translate } = useLocalizedText()

    const [referralLink, setReferralLink] = useState('')

    useEffect(() => {
        setReferralLink(
            `${translate(
                'registerNowForMT'
            )} @ https://www.mtmtx.xyz/auth/register/?ref=${userData.addr}`
        )
    }, [userData.addr, translate])

    return (
        <>
            <div className="mt-10 w-full text-center font-mono text-lg font-bold text-white">
                {translate('myWalletID')}
            </div>
            <div className="mt-4 flex w-full flex-row items-center justify-center gap-1">
                <div className="overflow-hidden text-ellipsis text-sm text-white">
                    {userData.addr}
                </div>
                <CopyToClipboard text={userData.addr ?? ''}>
                    <FaRegCopy
                        onClick={() =>
                            toast.success(translate('copyWalletAddress'), {
                                toastId: 'wallet copy',
                            })
                        }
                        className="hover:cursor-pointer"
                    />
                </CopyToClipboard>
            </div>
            <div className="mt-5 flex w-full justify-center">
                <QRCode
                    size={256}
                    className="max-w-20 h-auto rounded-md bg-white p-5"
                    value={referralLink}
                    viewBox="0 0 256 256"
                />
            </div>
            <div className="mt-5 w-full text-center text-sm">
                {translate('qrText')}
            </div>
            <ShareOptions referralLink={referralLink} />
        </>
    )
}

export default InviteContent
