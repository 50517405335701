import React from 'react'
import { AiOutlineHome, AiOutlineUserAdd } from 'react-icons/ai'
import { GiCoins, GiChart } from 'react-icons/gi'
import { ItemType } from '../components/MenuItem'
import tourImg from '../assets/tour.png'
import { useLocalizedText } from '../hooks/useLocalizedText'

const MenuList = () => {
    const { translate } = useLocalizedText()
    const menus: Record<string, string> = {
        home: translate('home'),
        wallet: translate('wallet'),
        profile: translate('profile'),
        history: translate('history'),
        tour: translate('tour'),
    }

    const menuList: Array<ItemType & { icon: React.ReactNode }> = [
        {
            name: menus.home,
            title: menus.home,
            url: 'home',
            icon: <AiOutlineHome className="h-full w-full" />,
        },
        {
            name: menus.wallet,
            title: menus.wallet,
            url: 'wallet',
            icon: <GiCoins className="h-full w-full" />,
        },
        {
            name: menus.tour,
            title: menus.tour,
            url: 'tour',
            icon: <img src={tourImg} />,
        },
        {
            name: menus.profile,
            title: menus.profile,
            url: 'profile',
            icon: <AiOutlineUserAdd className="h-full w-full" />,
        },
        {
            name: menus.history,
            title: menus.history,
            url: 'history',
            icon: <GiChart className="h-full w-full" />,
        },
    ]

    return menuList
}

export default MenuList
