import goldBar from '../../../../assets/goldbar.jpg'
import { useAppConfig } from '../../../../contexts/AppConfigProvider'
import { useLocalizedText } from '../../../../hooks/useLocalizedText'

export const GoldBar = () => {
    const { userData } = useAppConfig()

    const { translate } = useLocalizedText()

    return (
        <div className="flex flex-col items-center justify-center p-4">
            <img src={goldBar} className="w-full rounded-lg" alt="goldBar" />
            <div className="mt-4 w-full text-left text-xl font-bold">
                {translate('termAndCondition')}
            </div>
            <div className="mt-2 text-left">{translate('termContent')}</div>
            <div className="mt-4 w-1/2 rounded-lg border-2 border-yellow-500 p-2">
                <div className="w-full text-center">
                    {translate('achieved')}: {userData.goldBarCount ?? 0}
                </div>
            </div>
        </div>
    )
}
