import { useEffect, useState, useCallback } from 'react'
import { useNavigate } from 'react-router-dom'

import main from '../../assets/main.svg'
import mainLogo from '../../assets/main-logo.png'
import { useAppConfig } from '../../contexts/AppConfigProvider'
import { useWeb3Manage } from '../../contexts/Web3ManageProvider'
import { useAuth } from '../../contexts/AuthProvider'
import RegisterModal from '../../components/RegisterModal'
import { useLocalizedText } from '../../hooks/useLocalizedText'

const Landing = () => {
    const { account, activate, isConnected } = useWeb3Manage()
    const { isRegistrationPaymentNeeded, updateLoadingState } = useAppConfig()
    const { updateModalState } = useAppConfig()
    const { authenticate, canLogin } = useAuth()
    const navigate = useNavigate()
    const { translate, handleChangeLanguage, currentLanguage } =
        useLocalizedText()
    const [isHandleByUser, setIsHandleByUser] = useState<boolean>(false)

    const handleActivate = useCallback(() => {
        setIsHandleByUser(true)
        activate()
    }, [activate])

    useEffect(() => {
        const handleAuthenticate = async () => {
            setIsHandleByUser(false)

            if (account) {
                updateLoadingState(true)
                authenticate(account)
                updateLoadingState(false)
            }
        }

        if (isConnected && isHandleByUser) {
            handleAuthenticate()
        }
    }, [
        account,
        authenticate,
        isConnected,
        isHandleByUser,
        navigate,
        updateLoadingState,
        updateModalState,
    ])

    useEffect(() => {
        if (isConnected) {
            if (canLogin) {
                updateModalState(false)
                navigate(
                    isRegistrationPaymentNeeded
                        ? '/user/home/upgrade'
                        : '/user/home'
                )
            } else {
                updateModalState(true)
            }
        }
    }, [
        canLogin,
        isConnected,
        isRegistrationPaymentNeeded,
        navigate,
        updateModalState,
    ])

    return (
        <div
            className="relative flex h-full w-full flex-col items-center justify-around bg-cover bg-center"
            style={{ backgroundImage: `url(${main})` }}>
            <div className="absolute top-5 right-5">
                <select
                    className="appearance-none rounded-lg bg-gray-600 px-4 py-2 text-white hover:bg-gray-700 focus-visible:outline-none"
                    style={{ backgroundImage: 'none' }}
                    value={currentLanguage}
                    onChange={(e) => handleChangeLanguage(e.target.value)}>
                    <option value="en">English</option>
                    <option value="zh">Chinese</option>
                </select>
            </div>
            {!isConnected && (
                <>
                    <div className="flex flex-col items-center justify-center gap-5">
                        <img
                            src={mainLogo}
                            alt="MT"
                            className="h-[70px] w-[190px]"
                        />
                        <div className="text-center text-xl font-bold">
                            {translate('welcome')}
                        </div>
                    </div>
                    <div
                        className="rounded-full bg-yellow-200 px-16 py-3 text-lg font-bold text-black hover:cursor-pointer"
                        onClick={handleActivate}>
                        {translate('register')}
                    </div>
                </>
            )}
            <RegisterModal />
        </div>
    )
}

export default Landing
