import { useEffect, useState } from 'react'
import { Carousel } from 'react-responsive-carousel'
import 'react-responsive-carousel/lib/styles/carousel.min.css'
import Web3 from 'web3'

import homeImage from '../../../../assets/home.png'
import home2Image from '../../../../assets/home2.jpg'
import rankingPng from '../../../../assets/ranking.png'
import { getUsdtBalance } from '../../../../apis/contract'
import CheckAuthentication from '../../../../components/CheckAuthentication'
import { useAppConfig } from '../../../../contexts/AppConfigProvider'
import { toast } from 'react-toastify'
import CopyToClipboard from 'react-copy-to-clipboard'
import { MdContentCopy } from 'react-icons/md'
import { Link } from 'react-router-dom'
import { useLocalizedText } from '../../../../hooks/useLocalizedText'

const MainHome = () => {
    const { userData, parentAddr } = useAppConfig()
    const { translate } = useLocalizedText()

    const [usdtBalance, setUsdtBalance] = useState<string>('')

    useEffect(() => {
        const loadData = async () => {
            if (userData.addr) {
                await getUsdtBalance(userData.addr)
                    .then((balance) => {
                        setUsdtBalance(
                            Web3.utils.fromWei(balance.toString(), 'mwei')
                        )
                    })
                    .catch(() => {})
            }
        }
        loadData()
    }, [userData.addr])

    return (
        <CheckAuthentication>
            <div className="flex flex-col px-5 text-white">
                <Carousel
                    showThumbs={false}
                    autoPlay={true}
                    infiniteLoop={true}
                    className="mb-3 w-full bg-gray-400">
                    <img className="h-full w-full p-[1px]" src={homeImage} />
                    <img className="h-full w-full p-[1px]" src={home2Image} />
                </Carousel>
                <div className="mt-6 flex w-full items-center justify-center">
                    <Link to="goldbar" className="w-full">
                        <button className="w-full rounded-lg bg-gradient-to-r from-yellow-500 to-yellow-600 px-10 py-3 text-lg font-semibold text-white shadow-lg">
                            {translate('goldBarAchievement')}
                        </button>
                    </Link>
                </div>
                <div className="mt-6 flex w-full items-center justify-between rounded-full border-[1px] border-yellow-600 bg-gray-600 px-10 py-4">
                    <div className="text-lg font-semibold text-white">
                        {translate('referralLink')}
                    </div>
                    <div className="cursor-pointer">
                        <CopyToClipboard
                            text={`https://www.mtmtx.xyz/auth/register/?ref=${userData.addr}`}>
                            <MdContentCopy
                                onClick={() => {
                                    toast.success(
                                        translate('referralLinkCopied'),
                                        {
                                            toastId: 'home referral copy',
                                        }
                                    )
                                }}
                                size={20}
                            />
                        </CopyToClipboard>
                    </div>
                </div>
                <div className="mt-6 grid grid-cols-2 gap-4 px-5">
                    <div className="flex justify-center">
                        <img
                            className="w-[65%] rounded-full border-[3px] border-white p-4"
                            src={rankingPng}
                        />
                    </div>
                    <div className="flex flex-col items-center justify-center text-[27px] font-semibold leading-[32px] text-[#ffc000]">
                        <div className="">{translate('ranking')}</div>
                        <div className="">V{userData.level}</div>
                    </div>
                </div>

                <div className="mt-6 flex w-full flex-col rounded-xl border-[1px] border-yellow-600 bg-gray-600 p-3">
                    <div className="text-lg font-bold italic">
                        {translate('accountOverview')}
                    </div>
                    <div className="flex w-full flex-row">
                        <div className="mt-5 flex w-full flex-col gap-1 px-2 text-sm text-white">
                            <div className="flex w-full flex-row">
                                <div className="w-1/2">
                                    {translate('walletAddress')}
                                </div>
                                <div className="w-1/2 overflow-hidden text-ellipsis">
                                    {`${userData.addr?.slice(
                                        0,
                                        9
                                    )}...${userData.addr?.slice(-7)}`}
                                </div>
                            </div>
                            <div className="flex w-full flex-row">
                                <div className="w-1/2">USDT</div>
                                <div className="w-1/2">{usdtBalance}</div>
                            </div>
                            <div className="flex w-full flex-row">
                                <div className="w-1/2">
                                    {translate('myParent')}
                                </div>
                                <div className="w-1/2">
                                    {parentAddr !== 'admin'
                                        ? `${parentAddr.slice(
                                              0,
                                              9
                                          )}...${parentAddr.slice(-7)}`
                                        : translate('no')}
                                </div>
                            </div>
                            <div className="flex w-full flex-row">
                                <div className="w-1/2">
                                    {translate('myDirectTeam')}
                                </div>
                                <div className="w-1/2">
                                    {userData.childCount}
                                </div>
                            </div>
                            <Link
                                to="upgrade"
                                className={`mt-5 mb-2 rounded-3xl bg-yellow-600 py-3 text-center text-lg font-bold text-white hover:cursor-pointer`}>
                                {`${translate('upgradeRankingTo')} V${
                                    Number(userData.level) + 1
                                }`}
                            </Link>
                        </div>
                    </div>
                </div>

                <div className="my-5 flex w-full flex-col rounded-xl border-[1px] border-yellow-600 bg-gray-600 p-3">
                    <div className="text-center text-lg font-bold">MTX</div>
                    <div className="flex w-full flex-row">
                        <div className="flex w-full flex-col gap-1 px-2 text-sm text-white">
                            <Link
                                to="claim-list"
                                className={`mt-5 mb-2 rounded-3xl bg-yellow-600 py-3 text-center text-lg font-bold text-white hover:cursor-pointer`}>
                                {translate('claimList')}
                            </Link>
                        </div>
                    </div>
                </div>
            </div>
        </CheckAuthentication>
    )
}

export default MainHome
