import CheckAuthentication from '../../../components/CheckAuthentication'
import { useAppConfig } from '../../../contexts/AppConfigProvider'
import { useLocalizedText } from '../../../hooks/useLocalizedText'

const Header = () => {
    const { currentPage } = useAppConfig()
    const { handleChangeLanguage, currentLanguage } = useLocalizedText()

    return (
        <CheckAuthentication>
            <div className="sticky top-0 left-0 z-50 flex h-[70px] w-full flex-row items-center justify-between bg-[#1b3c5f] px-4 sm:h-[80px]">
                <div className="w-full text-center text-xl font-bold italic text-white">
                    {currentPage}
                </div>
                <select
                    className="appearance-none rounded-lg bg-gray-600 px-4 py-2 text-white hover:bg-gray-700 focus-visible:outline-none"
                    style={{ backgroundImage: 'none' }}
                    value={currentLanguage}
                    onChange={(e) => handleChangeLanguage(e.target.value)}>
                    <option value="en">EN</option>
                    <option value="zh">CN</option>
                </select>
            </div>
        </CheckAuthentication>
    )
}

export default Header
