import { useMutation } from '@apollo/client'
import { useNavigate } from 'react-router-dom'
import { toast } from 'react-toastify'
import { w3 } from '../apis/contract'
import { CREATE_USER } from '../apis/graphql/requests'

import { useAppConfig } from '../contexts/AppConfigProvider'
import { useAuth } from '../contexts/AuthProvider'
import { useWeb3Manage } from '../contexts/Web3ManageProvider'
import CustomModal from './Modal'
import mainLogo from '../assets/main-logo.png'
import { useLocalizedText } from '../hooks/useLocalizedText'

const RegisterModal = () => {
    const {
        parentAddr,
        updateParentAddr,
        updateModalState,
        updateLoadingState,
    } = useAppConfig()
    const { authenticate } = useAuth()
    const navigate = useNavigate()
    const { account, deactivate } = useWeb3Manage()
    const [request] = useMutation(CREATE_USER)
    const { translate } = useLocalizedText()

    const handleReferralUpdate = (e: React.ChangeEvent<HTMLInputElement>) => {
        updateParentAddr(e.currentTarget.value)
    }

    const handleModalCancel = () => {
        updateModalState(false)
        updateParentAddr('')
        deactivate()
        navigate('/')
    }

    const handleModalRegister = async () => {
        const signature = await w3.eth.personal.sign(
            parentAddr.slice(3),
            account ?? '',
            ''
        )

        await request({
            variables: {
                user: {
                    walletAddress: account ?? '',
                    parentAddress: parentAddr,
                    signature,
                },
            },
        })
            .then((result) => {
                updateModalState(false)
                updateParentAddr('')
                authenticate(result.data.createUser.walletAddress)
            })
            .catch((e) => {
                toast.warning(e.message)
            })
            .finally(() => {
                updateLoadingState(false)
            })
    }

    return (
        <CustomModal className="top-20 bottom-20 left-5 right-5 sm:left-20 sm:right-20">
            <div className="flex h-full w-full items-center justify-center">
                <div className="-mt-20 flex flex-col justify-center">
                    <div className="mb-32 flex flex-col items-center justify-center gap-5">
                        <img
                            src={mainLogo}
                            alt="MT"
                            className="h-[70px] w-[190px]"
                        />
                        <div className="text-center text-xl font-bold text-red-600">
                            {translate('welcome')}
                        </div>
                    </div>
                    <div className="text-lg font-bold text-black">
                        {translate('enterReferral')}
                    </div>
                    <input
                        className="mt-3 mb-10 h-10 rounded-md bg-slate-400 px-2 font-mono text-lg text-black focus-visible:outline-none"
                        value={parentAddr}
                        onChange={handleReferralUpdate}
                    />
                    <div className="flex flex-row justify-between">
                        <div
                            className="font-medium hover:cursor-pointer hover:text-red-500"
                            onClick={handleModalCancel}>
                            {translate('cancel')}
                        </div>
                        <div
                            className="font-medium hover:cursor-pointer hover:text-green-500"
                            onClick={handleModalRegister}>
                            {translate('register')}
                        </div>
                    </div>
                </div>
            </div>
        </CustomModal>
    )
}

export default RegisterModal
