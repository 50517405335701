import { Link } from 'react-router-dom'
import { useAppConfig } from '../contexts/AppConfigProvider'
import { useLocalizedText } from '../hooks/useLocalizedText'

export interface ItemType {
    name: string
    url: string
    icon: React.ReactNode
    title?: string
}

const MenuItem = ({ name, title, url, icon }: ItemType) => {
    const { updateCurrentPage, isRegistrationPaymentNeeded, currentPage } =
        useAppConfig()
    const { translate } = useLocalizedText()

    return (
        <Link
            to={isRegistrationPaymentNeeded ? '#' : url}
            onClick={
                isRegistrationPaymentNeeded
                    ? undefined
                    : () => updateCurrentPage(title ?? '')
            }
            className={`flex flex-col items-center justify-center ${
                currentPage === title ? 'text-yellow-300' : ''
            }`}>
            {title === translate('tour') ? (
                <div
                    className={`mt-[-40px] h-[75px] w-[75px] rounded-full p-2 sm:h-[85px] sm:w-[85px] ${
                        currentPage === title
                            ? 'bg-[#c55b11]'
                            : 'bg-gray-800/50'
                    }`}>
                    {icon}
                </div>
            ) : (
                <div className="flex flex-col items-center justify-center">
                    <div className="h-[25px] w-[25px] sm:h-[30px] sm:w-[30px]">
                        {icon}
                    </div>
                    <div className="text-[12px]">{name}</div>
                </div>
            )}
        </Link>
    )
}

export default MenuItem
