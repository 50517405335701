import {
    createContext,
    FC,
    PropsWithChildren,
    useContext,
    useState,
    useMemo,
} from 'react'

interface UserDataType {
    addr?: string
    parentAddr?: string
    level?: number
    childCount?: number
    stxAmount?: number
    goldBarCount?: number
}

interface AppConfigProps {
    userData: UserDataType
    openModal: boolean
    parentAddr: string
    currentPage: string
    loading: boolean
    isRegistrationPaymentNeeded: boolean
    updateParentAddr: (addr: string) => void
    updateLoadingState: (flag: boolean) => void
    updateModalState: (isOpen: boolean) => void
    updateCurrentPage: (page: string) => void
    updateUserData: (data: UserDataType) => void
}

export const AppConfig = createContext<AppConfigProps>({
    userData: {},
    parentAddr: '',
    openModal: false,
    currentPage: '',
    loading: false,
    isRegistrationPaymentNeeded: false,
    updateLoadingState: () => {},
    updateUserData: () => {},
    updateParentAddr: () => {},
    updateModalState: () => {},
    updateCurrentPage: () => {},
})

export const useAppConfig = () => useContext(AppConfig)

const AppConfigProvider: FC<PropsWithChildren<unknown>> = ({ children }) => {
    const [parentAddr, setParentAddress] = useState<string>('')
    const [openModal, setOpenModal] = useState<boolean>(false)
    const [currentPage, setCurrentPage] = useState<string>('')
    const [userData, setUserData] = useState<UserDataType>({})
    const [loading, setLoading] = useState<boolean>(false)

    const isRegistrationPaymentNeeded = useMemo(
        () => userData.level === 0 && userData.parentAddr !== 'admin',
        [userData.level, userData.parentAddr]
    )

    const value = useMemo(
        () => ({
            parentAddr,
            openModal,
            currentPage,
            userData,
            loading,
            isRegistrationPaymentNeeded,
            updateLoadingState: setLoading,
            updateParentAddr: setParentAddress,
            updateModalState: setOpenModal,
            updateCurrentPage: setCurrentPage,
            updateUserData: setUserData,
        }),
        [
            currentPage,
            loading,
            openModal,
            parentAddr,
            userData,
            isRegistrationPaymentNeeded,
        ]
    )

    return <AppConfig.Provider value={value}>{children}</AppConfig.Provider>
}

export default AppConfigProvider
