import { TbLogout } from 'react-icons/tb'
import { useWeb3Manage } from '../../../../contexts/Web3ManageProvider'
import { useAuth } from '../../../../contexts/AuthProvider'
import { useNavigate } from 'react-router-dom'
import { useCallback } from 'react'
import { useLocalizedText } from '../../../../hooks/useLocalizedText'

const Setting = () => {
    const { deactivate } = useWeb3Manage()
    const { removeLogin } = useAuth()
    const { translate } = useLocalizedText()

    const navigate = useNavigate()

    const handleDeactivate = useCallback(() => {
        deactivate()
        removeLogin()
        navigate('/')
    }, [deactivate, navigate, removeLogin])

    return (
        <button
            type="button"
            className="group relative mt-80 flex items-center justify-center rounded-md border border-transparent bg-indigo-600 px-4 py-2 text-sm font-medium text-white hover:bg-indigo-700 focus:outline-none focus:ring-2 focus:ring-indigo-500 focus:ring-offset-2"
            onClick={handleDeactivate}>
            <TbLogout className="mr-2 h-5 w-5" />
            <span className="text-sm">{translate('logout')}</span>
        </button>
    )
}

export default Setting
