import { useEffect, useState } from 'react'
import CheckAuthentication from '../../../../components/CheckAuthentication'
import MemberTreeView from './MemberTreeView'
import { useAppConfig } from '../../../../contexts/AppConfigProvider'
import InviteContent from './InviteContent'
import Setting from './Setting'
import { useLocalizedText } from '../../../../hooks/useLocalizedText'

const TabButton = ({
    label,
    isSelected,
    onClick,
}: {
    label: string
    isSelected: boolean
    onClick: () => void
}) => (
    <div
        className={`w-1/2 border-2 py-1 text-center hover:cursor-pointer ${
            isSelected ? 'border-yellow-600 text-yellow-600' : ''
        }`}
        onClick={onClick}>
        {label}
    </div>
)

const Profile = () => {
    const { userData } = useAppConfig()
    const { translate } = useLocalizedText()

    const [selectedTab, setSelectedTab] = useState(0)

    const handleTab = (s: number) => setSelectedTab(s)

    return (
        <CheckAuthentication>
            <div className="flex flex-col px-5 text-white">
                <div className="flex w-full flex-row">
                    <TabButton
                        label={translate('myInvite')}
                        isSelected={selectedTab === 0}
                        onClick={() => handleTab(0)}
                    />
                    <TabButton
                        label={translate('myTeam')}
                        isSelected={selectedTab === 1}
                        onClick={() => handleTab(1)}
                    />
                    <TabButton
                        label={translate('setting')}
                        isSelected={selectedTab === 2}
                        onClick={() => handleTab(2)}
                    />
                </div>
                {selectedTab === 0 ? (
                    <InviteContent userData={userData} />
                ) : selectedTab === 1 ? (
                    <MemberTreeView userData={userData} />
                ) : (
                    <Setting />
                )}
            </div>
        </CheckAuthentication>
    )
}

export default Profile
