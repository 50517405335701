import { useEffect, useMemo } from 'react'
import { FC, PropsWithChildren } from 'react'
import { useLocation, useNavigate } from 'react-router-dom'
import { useAuth } from '../contexts/AuthProvider'
import { useWeb3Manage } from '../contexts/Web3ManageProvider'
import { useAppConfig } from '../contexts/AppConfigProvider'
import { useLocalizedText } from '../hooks/useLocalizedText'

const CheckAuthentication: FC<PropsWithChildren<unknown>> = ({ children }) => {
    const { isConnected } = useWeb3Manage()
    const { canLogin, removeLogin } = useAuth()
    const { isRegistrationPaymentNeeded, updateCurrentPage } = useAppConfig()
    const navigate = useNavigate()
    const location = useLocation()
    const { translate } = useLocalizedText()

    const menus: Record<string, string> = useMemo(
        () => ({
            home: translate('home'),
            wallet: translate('wallet'),
            profile: translate('profile'),
            history: translate('history'),
            tour: translate('tour'),
        }),
        [translate]
    )

    useEffect(() => {
        if (!isConnected || !canLogin) {
            removeLogin()
            navigate('/')
        } else if (isRegistrationPaymentNeeded) {
            navigate('/user/home/upgrade')
        }
    }, [
        canLogin,
        isConnected,
        isRegistrationPaymentNeeded,
        navigate,
        removeLogin,
    ])

    useEffect(() => {
        const currentMenu =
            Object.entries(menus).find(([key, value]) =>
                location.pathname.startsWith(`/user/${key}`)
            )?.[1] ?? ''
        updateCurrentPage(currentMenu)
    }, [location.pathname, menus, updateCurrentPage])

    return <> {children}</>
}

export default CheckAuthentication
